import {IWhoamiState} from './x_types';
import {LOG__VUEX_COMMITS} from "@/misc/Config";

export default {
    namespace: true,
    state: {
        dt: undefined,
        loadStatus: undefined,
        data: undefined
    } as IWhoamiState,
    mutations: {
        whoamiSetLoadStatus(state: IWhoamiState, loadStatus?: string) {
            state.dt = new Date();
            state.loadStatus = loadStatus;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> whoamiSetLoadStatus:', state.loadStatus)
        },
        whoamiSetData(state: IWhoamiState, data?) {
            state.dt = new Date();
            //if (!state.data) throw new Error('state.data undefined!!');
            state.data = data ? data : null;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> whoamiSetData:', state.data)
        },
        whoamiClear(state: IWhoamiState) {
            state.dt = new Date();
            state.loadStatus = undefined;
            state.data = undefined;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> whoamiClear:')
        },
    },

    actions: {
        whoamiSetLoadStatus(context, loadStatus?: string) {
            context.commit('whoamiSetLoadStatus', loadStatus)
        },
        whoamiSetData(context, data?) {
            context.commit('whoamiSetData', data)
        },
        whoamiClear(context) {
            context.commit('whoamiClear')
        },
    },

    getters: {
        whoamiGetLoadStatus(state: IWhoamiState) {
            const x = state.loadStatus;
            if (LOG__VUEX_COMMITS) console.log('vuex get -> whoamiGetLoadStatus:', x)
            return state.loadStatus;
        },
        whoamiGetData(state: IWhoamiState) {
            const x = state.data ? state.data : {};
            if (LOG__VUEX_COMMITS) console.log('vuex get -> whoamiGetData:', x)
            return x;
        },
    }
}
