import {AxiosResponse} from "axios";
import store from '@/store';

import {postSettingGosEnabled} from '@/services/Backend'
import {IFail} from "@/services/errors/IFail";
import {IBackendFail} from "@/services/errors/IBackendFail";
import {MAX_RELOAD_RETRIES} from "@/misc/Config";
import {LOAD_STATUS} from '@/misc/enums/EnumLoadStatus';
import {BACKEND_ERROR_CODE} from "@/misc/enums/EnumBackendErrorCodes";

const NAME = 'ApiGetEsiaEnabled';

export default {
    name: NAME,
    run() {
        console.log('ooo >> ' + NAME + ' start');
        store.commit('esiaSetEnabled', undefined);
        store.commit('esiaSetLoadStatus', LOAD_STATUS.LOADING);

        const onSuccess = function (axiosResponse: AxiosResponse) {
            console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [data =', axiosResponse.data, ']');
            if (axiosResponse.data === undefined || axiosResponse.data.errorCode === BACKEND_ERROR_CODE.NEED_RELOAD) return execute();
            if (!axiosResponse.data.setting) {
                return execute();
            }
            const data = {
                esiaEnabled: axiosResponse.data.setting.value
            }
            store.commit('esiaSetEnabled', data.esiaEnabled);
            store.commit('esiaSetLoadStatus', LOAD_STATUS.DONE);
            return data;
        }

        let retries = 0;
        const execute = function () {
            if (retries <= MAX_RELOAD_RETRIES) {
                retries++;
                return postSettingGosEnabled().then(onSuccess).catch(onError);
            } else {
                return Promise.reject({
                    errorCode: BACKEND_ERROR_CODE.UNAUTHORIZED
                } as IFail)
            }
        }

        const onError = function (fail: IBackendFail) {
            console.log('ooo << ' + NAME, fail);
            if (fail.errorCode == BACKEND_ERROR_CODE.NEED_RELOAD) {
                return execute();
            }
            return Promise.reject(fail);
        }

        return execute();
    },
}
