import {postRequestsLastStats} from '@/services/Backend'
import {AxiosResponse} from 'axios';
import {IBackendFail} from "@/services/errors/IBackendFail";
import {MAX_RELOAD_RETRIES} from "@/misc/Config";
import {BACKEND_ERROR_CODE} from "@/misc/enums/EnumBackendErrorCodes";
import {IFail} from "@/services/errors/IFail";
import store from "@/store";
import {LOAD_STATUS} from "@/misc/enums/EnumLoadStatus";

const NAME = 'ApiRequestsLastStats';

export default {
    name: NAME,
    run() {
        console.log('ooo >> ' + NAME + ' start');

        store.commit('requestsLastStatsSetLoadStatus', LOAD_STATUS.LOADING);

        const onSuccess = function (axiosResponse: AxiosResponse) {
            console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [data =', axiosResponse.data, ']');
            if (axiosResponse.data === undefined) return execute();

            store.commit('requestsLastStatsSetData', axiosResponse.data.stats);
            store.commit('requestsLastStatsSetLoadStatus', LOAD_STATUS.DONE);

            return Promise.resolve(axiosResponse.data.stats);
        }

        let retries = 0;
        const execute = function () {
            if (retries <= MAX_RELOAD_RETRIES) {
                retries++;
                return postRequestsLastStats().then(onSuccess).catch(onError);
            } else {
                return Promise.reject({
                    errorCode: BACKEND_ERROR_CODE.UNAUTHORIZED
                } as IFail)
            }
        }

        const onError = function (fail: IBackendFail) {
            console.log('ooo << ' + NAME + ' [fail =', fail, ']');
            if (fail.errorCode === BACKEND_ERROR_CODE.NEED_RELOAD) return execute();
            return Promise.reject(fail);
        }

        return execute();
    },

}