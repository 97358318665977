import {ISystemInfoState, ISystemInfoStateData} from './x_types';
import {LOG__VUEX_COMMITS} from "@/misc/Config";

export default {
    namespace: true,
    state: {
        dt: undefined,
        loadStatus: undefined,
        data: {
            debug: undefined
        } as ISystemInfoStateData,
    } as ISystemInfoState,

    mutations: {
        systemInfoSetLoadStatus(state: ISystemInfoState, loadStatus?: string) {
            state.loadStatus = loadStatus;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> systemInfoSetLoadStatus:', state.loadStatus)
        },
        systemSetInfoData(state: ISystemInfoState, data: ISystemInfoStateData) {
            state.data = data;
            state.dt = new Date();
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> systemSetInfoData:', state.data)
        }
    },

    actions: {
        systemInfoSetLoadStatus(context, loadStatus?: string) {
            context.commit('systemInfoSetLoadStatus', loadStatus)
        },
        systemSetInfoData(context, data: ISystemInfoStateData) {
            context.commit('systemSetInfoData', data)
        },
    },

    getters: {
        systemInfoGetLoadStatus(state: ISystemInfoState) {
            return state.loadStatus
        },
        systemInfoGetData(state: ISystemInfoState) {
            return state.data ? state.data : {};
        },
        systemInfoIsDebug(state: ISystemInfoState) {
            return state.data ? state.data.debug : false
        }
    }
}
