import { MAX_RELOAD_RETRIES } from "@/misc/Config";
import { BACKEND_ERROR_CODE } from "@/misc/enums/EnumBackendErrorCodes";
import { LOAD_STATUS } from '@/misc/enums/EnumLoadStatus';
import { postSystemInfo } from '@/services/Backend';
import { IBackendFail } from "@/services/errors/IBackendFail";
import { IFail } from "@/services/errors/IFail";
import store from '@/store';
import { ISystemInfoStateData } from '@/store/modules/system_info/x_types';
import { AxiosResponse } from "axios";

const NAME = 'ApiSystemInfo';

export default {
    name: NAME,
    run() {
        console.log('ooo >> ' + NAME + ' start');
        store.commit('systemInfoSetLoadStatus', LOAD_STATUS.LOADING);

        const onSuccess = function (axiosResponse: AxiosResponse) {
            console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [data =', axiosResponse.data, ']');
            if (axiosResponse.data === undefined) return execute();
            if (!axiosResponse.data.info) {
                console.log('ooo << ' + NAME, 'data.info undefined! retrying');
                return execute();
            }

            const info = axiosResponse.data.info;
            const data: ISystemInfoStateData = {
                buildTimestamp: info.buildTimestamp,
                debug: info.debug,
                emailValidationPattern: info.emailValidationPattern,
                esiaLogoutLink: info.esiaLogoutLink,
                fioValidationPattern: info.fioValidationPattern,
                partnerLink: info.partnerLink,
                passwordValidationPattern: info.passwordValidationPattern,
                productKiCheckMode: info.productKiCheckMode,
                productKiFreeByYear: info.productKiFreeByYear,
                productKiPrice: info.productKiPrice,
                productNkr12CheckMode: info.productNkr12CheckMode,
                productNkr12FreeByYear: info.productNkr12FreeByYear,
                productNkr12Price: info.productNkr12Price,
                productNkrCheckMode: info.productNkrCheckMode,
                productNkrFreeByYear: info.productNkrFreeByYear,
                productNkrPrice: info.productNkrPrice,
                productsMetaVersion: info.productsMetaVersion,
                refreshRate: info.refreshRate,
                robokassaEnabled: info.robokassaEnabled,
                settingLinksConsentProcessingPersonalData: info.settingLinksConsentProcessingPersonalData,
                settingLinksDesignEmpis: info.settingLinksDesignEmpis,
                settingLinksFeedback: info.settingLinksFeedback,
                settingLinksFooterBackToSiteNbch: info.settingLinksFooterBackToSiteNbch,
                settingLinksHeaderBackToSite: info.settingLinksHeaderBackToSite,
                settingLinksKiReadManual: info.settingLinksKiReadManual,
                settingLinksLkRules: info.settingLinksLkRules,
                settingLinksNbchBanner1: info.settingLinksNbchBanner1,
                settingLinksNbchBanner2: info.settingLinksNbchBanner2,
                settingLinksPersonalDataProcessingPolicy: info.settingLinksPersonalDataProcessingPolicy,
                subscriptionCountDaysAfterKiRequest: info.subscriptionCountDaysAfterKiRequest,
                subjectHistoryRefreshRateSeconds: info.subjectHistoryRefreshRateSeconds,
                subscriptionFraudProtectionCheckMode: info.subscriptionFraudProtectionCheckMode,
                subscriptionFraudProtectionFreeByYear: info.subscriptionFraudProtectionFreeByYear,
                subscriptionFraudProtectionPrice: info.subscriptionFraudProtectionPrice,
                subscriptionKiChangePrice: info.subscriptionKiChangePrice,
                subscriptionKiChangeFreeByYear: info.subscriptionKiChangeFreeByYear,
                subscriptionKiChangeCheckMode: info.subscriptionKiChangeCheckMode,
                subscriptionKiGetCheckMode: info.subscriptionKiGetCheckMode,
                subscriptionKiGetFreeByYear: info.subscriptionKiGetFreeByYear,
                subscriptionKiGetPrice: info.subscriptionKiGetPrice,
                version: info.version,
            } as ISystemInfoStateData;

            return store.dispatch('systemSetInfoData', data).then(() => {
                return store.dispatch('systemInfoSetLoadStatus', LOAD_STATUS.DONE);
            });
        }

        let retries = 0;
        const execute = function () {
            if (retries <= MAX_RELOAD_RETRIES) {
                retries++;
                return postSystemInfo().then(onSuccess).catch(onError);
            } else {
                return Promise.reject({
                    errorCode: BACKEND_ERROR_CODE.UNAUTHORIZED
                } as IFail)
            }
        }

        const onError = function (fail: IBackendFail) {
            console.log('ooo << ' + NAME + ' [fail =', fail, ']');
            if (fail.errorCode === BACKEND_ERROR_CODE.NEED_RELOAD) return execute();
            return Promise.reject(fail);
        }

        return execute();
    },

    isLoadDone(): boolean {
        return store.getters.systemInfoGetLoadStatus === LOAD_STATUS.DONE
    }
}
