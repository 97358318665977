import router from '@/router'
import {postLogout} from '@/services/Backend'
import {ROUTING} from '@/misc/enums/EnumRouting'
import store from '@/store'
import {AxiosError, AxiosResponse} from "axios";

const NAME = 'ApiLogout';

export default {
    name: NAME,
    run() {
        console.log('ooo >> ' + NAME + ' start');

        const clearPersonalInfo = () => {
            // noinspection JSIgnoredPromiseFromCall
            store.dispatch('whoamiClear');
            // noinspection JSIgnoredPromiseFromCall
            store.dispatch('profileClear');
        }

        postLogout()
            .then((axiosResponse: AxiosResponse) => {
                console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [dataSz =', axiosResponse.data.length, ']');
                clearPersonalInfo();
                console.log('-> redirect from p7');
                // noinspection JSIgnoredPromiseFromCall
                router.push(ROUTING.LOGIN);
            })
            .catch((axiosError: AxiosError) => {
                // ошибка и есть нормальный выход, тк теперь все данные сессии сброшены
                console.log('ooo << ' + NAME + ' done', axiosError.message);
                clearPersonalInfo();
                console.log('-> redirect from p8');
                // noinspection JSIgnoredPromiseFromCall
                router.push(ROUTING.LOGIN);
            });
    }
}