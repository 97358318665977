import {ISystemInfoStateData} from "@/store/modules/system_info/x_types";
import {computed, ComputedRef} from "vue";
import store from "@/store";

export default {
    info(): ComputedRef<ISystemInfoStateData> {
        return computed(() => {
            return store.getters.systemInfoGetData;
        });
    },

    isDebug(): boolean {
        const i = this.info();
        if (!i || !i.value) return false;
        return !!i.value.debug;
    },

    isEmpty(): boolean {
        const infoProperties = Object.keys(this.info().value);
        return infoProperties.length === 1 && infoProperties[0] === 'debug';
    }
}
