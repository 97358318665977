<template>
  <div class="nsc-error-background">
    <div id="error-layout">
      <div class="error-centred">
        <h2>Что-то пошло не так...</h2>
        <router-view/>
      </div>
    </div>
  </div>
</template>


<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<style lang="scss" scoped>

#error-layout {
  padding-left: 100px;
  padding-top: 100px;
  position: relative;
}

.nsc-error-background {
  background-image: url(../assets/images/bg2-x.jpg);
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  min-height: 800px;
  padding-top: 50px;
  padding-left: 50px
}

.error-centred {
  align-content: center;
}
</style>