import {AxiosResponse} from 'axios';
import store from '@/store'

import {postWhoami} from '@/services/Backend'
import {IFail} from "@/services/errors/IFail";
import {IBackendFail} from "@/services/errors/IBackendFail";
import {MAX_RELOAD_RETRIES} from "@/misc/Config";
import {LOAD_STATUS} from '@/misc/enums/EnumLoadStatus'
import {BACKEND_ERROR_CODE} from "@/misc/enums/EnumBackendErrorCodes";

const NAME = 'ApiWhoami';

export default {
    name: NAME,
    run() {
        console.log('ooo >> ' + NAME + ' start');
        store.commit('whoamiSetLoadStatus', LOAD_STATUS.LOADING);
        store.commit('whoamiSetData', undefined);

        const onSuccess = function (axiosResponse: AxiosResponse) {
            console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [data =', axiosResponse.data, ']');
            if (axiosResponse.data === undefined) return execute();
            const data = {
                privileges: axiosResponse.data.privileges,
                role: axiosResponse.data.role,
                username: axiosResponse.data.username
            }
            if (!data.privileges || !data.privileges.includes('SUBJECT_UI')) {
                return Promise.reject({
                    errorCode: BACKEND_ERROR_CODE.NOT_A_SUBJECT,
                    axiosError: undefined
                } as IBackendFail);
            } else {
                store.commit('whoamiSetData', data);
                store.commit('whoamiSetLoadStatus', LOAD_STATUS.DONE);
            }
        }

        let retries = 0;
        const execute = function () {
            if (retries <= MAX_RELOAD_RETRIES) {
                retries++;
                return postWhoami().then(onSuccess).catch(onError);
            } else {
                return Promise.reject({
                    errorCode: BACKEND_ERROR_CODE.UNAUTHORIZED
                } as IFail)
            }
        }

        const onError = function (fail: IBackendFail) {
            console.log('ooo << ' + NAME + ' [fail =', fail, ']');
            if (fail.errorCode === BACKEND_ERROR_CODE.NEED_RELOAD) return execute();
            return Promise.reject(fail);
        }

        return execute();
    },
}
