import { PRODUCTS } from "@/misc/enums/EnumProducts";

export const VUE_CABINET_COOKIE_NAME = 'sc_vue';
export const VUEX_PERSIST_COOKIES = false;

export const MAX_RELOAD_RETRIES = 3;
export const API_NA_RETRY_SECONDS = 5;
export const ERROR_PAGE_WAIT_FOR_REDIRECT_SEC = 30;
export const METRICS_DISABLE = false;
export const BACKEND_PAGE_NAME__LOGIN = 'perform_login';
export const BACKEND_PAGE_NAME__LOGOUT = 'logout';

export const TOAST_SUCCESS_DEFAULT_LIFETIME_SECONDS = 3;
export const TOAST_INFO_DEFAULT_LIFETIME_SECONDS = 3;
export const TOAST_WARN_DEFAULT_LIFETIME_SECONDS = 5;
export const TOAST_ERROR_DEFAULT_LIFETIME_SECONDS = 5;


export const MAX_REQUESTS_ALL_IN_TABLE = 1000;

export const FORGOT_RETRY_SECONDS = 30;
export const REGISTER_RETRY_SECONDS = 30;

export const LOG__ROUTER = false;
export const LOG__VUEX_COMMITS = false;
export const LOG__SC_INPUT_TEXT_DEBUG = false;
export const LOG__SC_INPUT_CB_DEBUG = false;

export const VERSION = require('../../package.json').version;

export const SHOW_HISTORY_PRODUCTS: string[] = [
    PRODUCTS.KI,
    PRODUCTS.PKR,
    PRODUCTS.PKR12,
    PRODUCTS.KI_CHANGE,
    PRODUCTS.FRAUD_PROTECTION,
    PRODUCTS.KI_GET
];
