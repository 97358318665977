import { METRICS_DISABLE } from "@/misc/Config";
import { GOALS } from "@/misc/enums/EnumMetricGoals";

// @ts-ignore
export class MetricsService {
    set window(value: Window) {
        this._window = value;
    }
    private static instance: MetricsService;

    /**
     * идентификаторы для метрик
     */
    private metricsFP_UID = '390851305606780';
    private metricsYA_UID = 9726127;

    /**
     * Проверка для изначальной инициализации
     */
    private initialized : boolean = false;

    private _window : Window;

    /**
     * Объекты метриковых API
     */
    private facebookPixel : any;
    private metricsYA : any;

    private goalsYACache : Array<String> = [];

    private constructor(windowObj: Window) {
        this._window = windowObj;
    }

    public static getInstance(windowObj: Window): MetricsService {
        if (!MetricsService.instance) {
            MetricsService.instance = new MetricsService(windowObj);
        }
        return MetricsService.instance;
    }

    private initialize() {
        if (METRICS_DISABLE) return;
        this.initialized = true;
        const tempThis = this;
        this.safeWrapMethod(function () {
            tempThis.facebookPixelInit();
        });
        this.safeWrapMethod(function () {
            tempThis.metricsYAInit1();
        });
    }

    public goal(goalName: GOALS) {
        if (METRICS_DISABLE) {
            return;
        }

        console.log('metrics service goal', goalName);

        if (!this.initialized) {
            console.log('initializing metrics...')
            this.initialize();
            console.log('initializing metrics done')
        }

        if (this.metricsYA) {
            try {
                this.metricsYA.reachGoal(goalName as string);
            } catch (e) {
                console.info(e);
            }
        } else {
            console.log("metricsYA is unavailable; goal cached");
            this.goalsYACache.push(goalName as string);
        }

        if (this.facebookPixel && goalName === GOALS.registr_na) {
            try {
                this.facebookPixel('track', 'CompleteRegistration', {currency: "RUB", value: 0.00});
            } catch (e) {
                console.info(e);
            }
        }
    }

    public onLocationChanged(path: string) {
        if (!this.initialized) {
            console.log('initializing metrics...')
            this.initialize();
            console.log('initializing metrics done')
        }
        try {
            if (this.metricsYA) {
                this.metricsYA.hit(path);
            }
        } catch (e) {
            console.info(e);
        }
    }

    private safeWrapMethod(x: Function) {
        try {
            x.call(this);
        } catch (e) {
            console.log('error inside metrics service', e);
        }
    }

    private loadScript (url: string, callback: Function) {
        try {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = url;
            // @ts-ignore
            if (script.readyState) {  // only required for IE <9
                // @ts-ignore
                script.onreadystatechange = function () {
                    // @ts-ignore
                    if (script.readyState === "loaded" || script.readyState === "complete") {
                        // @ts-ignore
                        script.onreadystatechange = null;
                        callback();
                    }
                };
            } else {  //Others
                script.onload = function () {
                    callback();
                };
            }
            document.getElementsByTagName("head")[0].appendChild(script);
        } catch (e) {
            console.info(e);
        }
    }

    private facebookPixelInit() {
        if ((this._window)['fbq']) {
            return;
        }
        const n : any = this._window['fbq'] = function () {
            // eslint-disable-next-line prefer-spread,prefer-rest-params
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!this._window['_fbq']) this._window['_fbq'] = n;
        n.push = [];
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        const t = document.createElement('script');
        t.async = !0;
        t.src = 'https://connect.facebook.net/en_US/fbevents.js';
        const s = document.getElementsByTagName('script')[0];
        if (s.parentNode != null) {
            s.parentNode.insertBefore(t, s);
            this.facebookPixel = n;
            this.facebookPixel('init', this.metricsFP_UID);
        }
    }

    private metricsYAInit1() {
        const thisTemp = this;

        this.loadScript('//mc.yandex.ru/metrika/watch.js', function () {
            try {
                // @ts-ignore
                thisTemp.metricsYA = new Ya.Metrika({id: thisTemp.metricsYA_UID, enableAll: true, webvisor: true});

                // @ts-ignore
                thisTemp.goalsYACache.forEach(goal => {
                    try {
                        console.log("metricsYA goal", goal, "from cache.");
                        thisTemp.metricsYA.reachGoal(goal);
                    } catch (e) {
                        console.info(e);
                    }
                });
            } catch (e) {
                console.info(e);
            }
        });

        if (!this.metricsYA) {
            return;
        }

        const yaRoot = document.createElement('script');
        yaRoot.setAttribute('display', 'none');
        const yaNoscript = document.createElement('noscript');
        const yaDivForImg = document.createElement('div');
        const yaImg = document.createElement('img');
        yaImg.setAttribute('src', '//mc.yandex.ru/watch/' + this.metricsYA_UID);
        yaImg.setAttribute('style', 'position:absolute; left:-9999px;');
        yaImg.setAttribute('alt', '');
        yaDivForImg.appendChild(yaImg);
        yaNoscript.appendChild(yaDivForImg);
        yaRoot.appendChild(yaNoscript);
        const metricsPlaceholder = document.getElementById('metrics_placeholder');
        if (!metricsPlaceholder) {
            throw new Error('cannot find Yandex Metric placeholder tag');
        } else {
            metricsPlaceholder.appendChild(yaRoot);
        }
    }

    private getEvent(goal) {
        if (goal.toLowerCase() === 'basket_click') {
            return {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: goal
            }
        } else {
            return {
                hitType: 'event',
                eventCategory: 'forms',
                eventAction: 'submit',
                eventLabel: goal
            }
        }
    }
}