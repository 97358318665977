
import '@/assets/css2/service.scss'
import {defineComponent} from 'vue';
import {ROUTING} from "@/misc/enums/EnumRouting";

export default defineComponent({
  props: {
    text: {type: String},
    showTooltip: {type: Boolean, default: false},
    position: {type: String, default: 'top'},
    hideMobile: {type: Boolean, default: false},
  },

  setup() {
    return {}
  },

  methods: {
    redirectToProfile() {
      this.$router.push(ROUTING.PROFILE);
    },
  }
})
