
import '@/assets/css2/main.scss';
import '@/assets/css2/header-main.scss';

import {defineComponent, onMounted, ref} from 'vue';
import router from "@/router";

import {defaultFailHandle} from "@/misc/ErrorHandler";
import {ROUTING} from "@/misc/enums/EnumRouting";

import ScMainHeading from '@/components/main/ScMainHeading.vue'
import ScFooter from '@/components/ScFooter.vue'

import {IFail} from "@/services/errors/IFail";
import ApiGetEsiaEnabled from '@/services/api/system/ApiGetEsiaEnabled';
import ApiSystemInfo from "@/services/api/system/ApiSystemInfo";
import ApiWhoami from '@/services/api/system/ApiWhoami';
import ApiRequestsLastStats from "@/services/api/requests/ApiRequestsLastStats";
import ApiProfileGet from "@/services/api/profile/ApiProfileGet";
import MiscInfo from "@/services/MiscInfo";

export default defineComponent({
  components: {
    ScMainHeading,
    ScFooter,
  },

  setup() {
    const loadingDone = ref(false);

    onMounted(async () => {
      await Promise.all([ApiWhoami.run(), ApiGetEsiaEnabled.run(), ApiRequestsLastStats.run(), ApiProfileGet.run()])
          .then(() => {
            loadingDone.value = true;
          })
          .catch((fail: IFail) => {
            if (!defaultFailHandle(fail)) {
              console.log('-> redirect from p6');
              router.push(ROUTING.LOGIN); //+
            }
          });
    });

    if (MiscInfo.isEmpty()) {
      ApiSystemInfo.run()
          .catch((fail: IFail) => {
            if (!defaultFailHandle(fail)) {
              console.log('-> redirect from p32');
              router.push(ROUTING.LOGIN); //+
            }
          });
    }

    return {
      loadingDone
    }
  },

  methods: {},

})
