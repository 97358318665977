
import {computed, defineComponent, getCurrentInstance} from "vue";
import router from "@/router";
// import {GOALS} from "@/misc/enums/EnumMetricGoals";

export default defineComponent({
  setup() {
    const app = getCurrentInstance();
    const metrics = app?.appContext.config.globalProperties.$metricsService;
    const currentRoute = computed(function () {
      return router.currentRoute.value.path;
    });
    if (!metrics) {
      console.error('cannot get metrics service from app; app is null')
    }
    return {
      metrics,
      currentRoute
    }
  },
  watch: {
    currentRoute(nv) {
      console.log('route changed to \'' + nv + '\', triggering metrics onLocationChanged...')
      this.metrics?.onLocationChanged(nv);
    }
  },
})
