import {IProfileData, IProfileState} from './x_types';
import {LOG__VUEX_COMMITS} from "@/misc/Config";

export default {
    namespace: true,
    state: {
        dt: undefined,
        loadStatus: undefined,
        data: undefined,
    } as IProfileState,

    mutations: {
        profileRefreshDt(state: IProfileState) {
            state.dt = (new Date()).getTime();
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> profileRefreshDt')
        },
        profileSetLoadStatus(state: IProfileState, status?: string) {
            state.loadStatus = status;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> profileSetLoadStatus:', state.loadStatus)
        },
        profileSetData(state: IProfileState, data: IProfileData) {
            state.data = data;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> profileSetData:', state.data)
        },
        profileClear(state: IProfileState) {
            state.loadStatus = undefined;
            state.data = undefined;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> profileClear')
        },
    },

    actions: {
        profileRefreshDt(context) {
            context.commit('profileRefreshDt');
        },
        profileSetLoadStatus(context, status?: string) {
            context.commit('profileSetLoadStatus', status);
        },
        profileSetData(context, data: IProfileData) {
            context.commit('profileSetData', data);
            context.commit('profileRefreshDt');
        },
        profileClear(context) {
            context.commit('profileClear', status);
        },
    },

    getters: {
        profileGetAgeMs(state: IProfileState) {
            const now = (new Date()).getTime();
            const past = (new Date(Date.UTC(-271821, 3, 20))).getTime()
            const was = state.dt ? state.dt : past;
            return now - was;
        },
        profileGetLoadStatus(state: IProfileState) {
            return state.loadStatus;
        },
        profileGetData(state: IProfileState): IProfileData | undefined {
            return state.data ? state.data : undefined;
        },
    }
}
