export const enum REQUEST_STATUS {
    DONE = 'DONE',
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    SC_ACCEPTED = 'SC_ACCEPTED',
    PAY_OR_NOT = 'PAY_OR_NOT',
    SC_REJECTED = 'SC_REJECTED',
    GOS_ACCEPTED = 'GOS_ACCEPTED',
    PAY_WAIT = 'PAY_WAIT',
    GOS_REJECTED = 'GOS_REJECTED',
    GOS_NOT_CONFIRMED = 'GOS_NOT_CONFIRMED',
    CONFIRMING = 'CONFIRMING',
    DBO_MUST_RETRY_SEND = 'DBO_MUST_RETRY_SEND',
    ERROR_NKR_NOT_AVAIL = 'ERROR_NKR_NOT_AVAIL',
    ERROR_NKR_NOT_INFORMATIVE = 'ERROR_NKR_NOT_INFORMATIVE',
    ERROR_NKR_NO_CREDS_BEFORE = 'ERROR_NKR_NO_CREDS_BEFORE',
    PROMO_CODE = 'PROMO_CODE',
    ERROR = 'ERROR',
}