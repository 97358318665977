
import {defineComponent} from 'vue'
import router from '@/router'

import AuthLayout from '@/layouts/AuthLayout.vue'
import ErrorLayout from '@/layouts/ErrorLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'

import Toast from "primevue/toast";
import ScModal from "@/components/modal/ScModal.vue";

import {LOG__ROUTER} from "@/misc/Config";

import MetricsComponentWithoutTemplate from "@/misc/MetricsRouteWatch.vue";

export default defineComponent({
  components: {ScModal, AuthLayout, ErrorLayout, MainLayout, Toast, MetricsComponentW: MetricsComponentWithoutTemplate},

  mounted() {
    // при каждом переходе на новую страницу очищаем алерты
    router.beforeEach(() => {
      this.$toast.removeAllGroups();
    })
  },

  computed: {
    layout() {
      let layoutName = this.$route.meta.layout + '-layout';
      if (LOG__ROUTER) console.log('router meta -> ', {
        meta: this.$route.meta,
        layoutName: layoutName
      });
      return layoutName;
    },
    title() {
      return this.$route.meta.title ? this.$route.meta.title : 'Личный кабинет';
    }
  },

  watch: {
    title(nv) {
      window.document.title = nv;
    }
  },
})
