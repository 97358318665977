import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "modal",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    dismissableMask: "",
    modal: ""
  }, {
    header: _withCtx(() => [
      (_ctx.header)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.header), { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (_ctx.footer)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.footer), { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.body)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.body), { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}