
import '@/assets/css2/footer.scss';

import { VERSION } from "@/misc/Config";
import store from "@/store";
import { ISystemInfoStateData } from "@/store/modules/system_info/x_types";
import { computed, ComputedRef, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const {ROUTING} = require("@/misc/enums/EnumRouting");

    const info: ComputedRef<ISystemInfoStateData> = computed(() => {
      return store.getters.systemInfoGetData;
    });

    const linkPersonalDataProcessingPolicy = computed(() => {
        if (info.value)
        {
            return info.value.settingLinksPersonalDataProcessingPolicy ?? info.value.settingLinksConsentProcessingPersonalData;
        }

        return '';
    });
    const linkFeedback = computed(() => info.value ? info.value.settingLinksFeedback : '');
    const linkLkRules = computed(() => info.value ? info.value.settingLinksLkRules : '');
    const needToShowCurrentFeedbackPage = computed(() => useRouter().currentRoute.value.meta?.layout === 'main');
    const yearNow = new Date().getFullYear();

    return {
      VERSION,
      ROUTING,
      yearNow,
      linkPersonalDataProcessingPolicy,
      linkFeedback,
      linkLkRules,
      needToShowCurrentFeedbackPage
    }
  }
})

