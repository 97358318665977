import {postClickBankOffer} from '@/services/Backend'
import {AxiosResponse} from 'axios';
import {IBackendFail} from "@/services/errors/IBackendFail";

const NAME = 'ApiBankOffersClick';

export default {
    name: NAME,
    run(input: {
        bankOfferId: number
    }) {
        console.log('ooo >> ' + NAME + ' start');

        const execute = () => {
            return postClickBankOffer(input.bankOfferId).then(onSuccess).catch(onError);
        };

        const onSuccess = function (axiosResponse: AxiosResponse) {
            console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [data =', axiosResponse.data, ']');
            return Promise.resolve();
        }

        const onError = function (fail: IBackendFail) {
            console.log('ooo << ' + NAME + ' [fail =', fail, ']');
            return Promise.reject();
        }

        return execute();
    },

}