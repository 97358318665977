import * as Cookies from '@/../node_modules/js-cookie';
import { BACKEND_PAGE_NAME__LOGIN, BACKEND_PAGE_NAME__LOGOUT } from "@/misc/Config";
import { AXIOS_ERROR_MESSAGE } from "@/misc/enums/EnumAxiosErrorMessage";
import { BACKEND_ERROR_CODE } from "@/misc/enums/EnumBackendErrorCodes";
import { ESIA_GOAL } from "@/misc/enums/EnumEsiaGoal";
import { IBackendFail } from "@/services/errors/IBackendFail";
import { urlEncode } from "@/services/Misc";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const URL_ROOT = '';

const URL_CONTEXT = URL_ROOT + '/subject-cabinet';
const URL_LOGIN = URL_CONTEXT + '/' + BACKEND_PAGE_NAME__LOGIN;
const URL_LOGOUT = URL_CONTEXT + '/' + BACKEND_PAGE_NAME__LOGOUT;
const URL_API = URL_CONTEXT + '/api';
const URL_API_SYSTEM = URL_API + '/system';
const URL_API_ESIA = URL_API + '/esia';
const URL_API_ANONYMOUS = URL_API + '/anonymous';
const URL_API_SUBJECT = URL_API + '/subject';

const URL_API_V2 = URL_API + '/v2';
const URL_API_V2_SYSTEM = URL_API_V2 + '/system';
const URL_API_V2_ESIA = URL_API_V2 + '/esia';
const URL_API_V2_ANONYMOUS = URL_API_V2 + '/anonymous';
const URL_API_V2_SUBJECT = URL_API_V2 + '/subject';

const URL_API_V3 = URL_API + '/v3';
const URL_API_V3_SUBJECT = URL_API_V3 + '/subject';

const axiosInstance = axios.create({
    baseURL: URL_ROOT,
    withCredentials: true,
    headers: {
        "platform": "WEB"
    }
});

const axiosFormDataInstance = axios.create({
    baseURL: URL_ROOT,
    withCredentials: true,
    headers: {
        "platform": "WEB",
        "Content-Type" : "multipart/form-data"
    }
});

axiosInstance.interceptors.request.use((requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
    return requestConfig;
});

axiosInstance.interceptors.response.use(
    (axiosResponse: AxiosResponse): any => {
        let dataStr = JSON.stringify(axiosResponse.data);
        if (dataStr) dataStr = dataStr.trim();

        if (dataStr.startsWith('This session has been expired')) {
            console.log('Session expired. Need to drop cookies!');
            return Promise.reject({
                errorCode: BACKEND_ERROR_CODE.UNAUTHORIZED,
            });
        } else if (dataStr.startsWith('"<')
            && !axiosResponse.config.url?.endsWith(BACKEND_PAGE_NAME__LOGIN)
            && !axiosResponse.config.url?.endsWith(BACKEND_PAGE_NAME__LOGOUT)) {
            //console.log("====== NEED_RELOAD")
            return Promise.reject({
                errorCode: BACKEND_ERROR_CODE.NEED_RELOAD,
            });
        } else {
            return axiosResponse;
        }
    },
    (axiosError: AxiosError) => {
        let errorCode;
        if (axiosError) {
            if (axiosError.message === AXIOS_ERROR_MESSAGE.NETWORK_ERROR) {
                errorCode = BACKEND_ERROR_CODE.NETWORK_ERROR;
            } else if (axiosError.response && axiosError.response.status === 403) {
                if (Cookies.get('X-authentication')) {
                    errorCode = BACKEND_ERROR_CODE.UNAUTHORIZED;
                } else {
                    errorCode = BACKEND_ERROR_CODE.GO_TO_LOGIN;
                }

            }
        }
        return Promise.reject({
            errorCode: errorCode,
            error: axiosError
        } as IBackendFail);
    });


export function systemCheckAvail() {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: URL_API_SYSTEM + '/invalidsession',
        timeout: 10000
    }
    return axiosInstance(requestConfig);
}

export function checkEsia() {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: URL_API_ESIA + '/start',
        timeout: 10000
    }
    return axiosInstance(requestConfig);
}

export function signInOrRegisterByEsia(esiaGoal: ESIA_GOAL) {
    const params = {
        esiaGoal: esiaGoal
    }
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: URL_API_ESIA + '/start-sign-in-by-esia',
        timeout: 10000,
        params: params
    }
    return axiosInstance(requestConfig);
}

export function postStartRegisterByEsia(data: {
    username?: string,
    name?: string,
    surname?: string,
    middleName?: string,
    birthday?: string,
    docType: string,
    passportSeries?: string,
    passportNumber?: string
}) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_ESIA + '/start-register-by-esia',
        timeout: 10000,
        data,
    };
    return axiosInstance(requestConfig);
}

export function postSystemInfo() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SYSTEM + '/info',
        timeout: 20000
    }
    return axiosInstance(requestConfig);
}

export function postLogin(login: string, password: string, captcha?: string) {
    const data = {username: login, password: password};
    if (captcha) data['captcha'] = captcha;
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_LOGIN,
        data: urlEncode(data)
    }
    return axiosInstance(requestConfig);
}

export function postRegister(login: string, password: string, mailing: boolean, agreement: boolean) {
    const data = {
        username: login,
        password: password,
        mailing: mailing,
        thirdPartyAgreement: agreement
    };
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_ANONYMOUS + "/register",
        data: data
    };
    return axiosInstance(requestConfig);
}

export function postGetProfile() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_ANONYMOUS + "/get-identity-for-esia-created-subject"
    };
    return axiosInstance(requestConfig);
}

export function postRegisterLetterRetry(login: string) {
    const data = {username: login};
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_ANONYMOUS + "/retry-register",
        data: data
    };
    return axiosInstance(requestConfig);
}

export function postForgot(login: string) {
    const data = {username: login};
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_ANONYMOUS + "/forgot_pass",
        data: data
    };
    return axiosInstance(requestConfig);
}

export function postRestorePassword(newPassword: string, uuid: string) {
    const data = {uuid: uuid, password: newPassword};
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_ANONYMOUS + "/forgot_form",
        data: urlEncode(data)
    };
    return axiosInstance(requestConfig);
}

export function postCreateIdentityByLogin(
    login: string,
    name: string,
    surname: string,
    patronymic: string,
    birthday: string,
    docType: string,
    passportSeries: string,
    passportNumber: string) {
    const data = {
        username: login,
        name: name,
        surname: surname,
        docType: docType,
        passportSeries: passportSeries,
        passportNumber: passportNumber,
        birthday: birthday
    }
    if (patronymic) {
        data['middleName'] = patronymic;
    }
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_ANONYMOUS + "/create_identity",
        data: data
    };
    return axiosInstance(requestConfig);
}

export function postRegistrationConfirm(uuid: string) {

    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_ANONYMOUS + "/confirm",
        data: urlEncode({
            confirmationUuid: uuid
        })
    };
    return axiosInstance(requestConfig);
}

export function postLoginChangeConfirm(uuid: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_ANONYMOUS + "/login_change_confirm",
        data: urlEncode({
            uuid: uuid
        })
    };
    return axiosInstance(requestConfig);
}

export function getCaptcha(login: string, refresh?: boolean) {
    const params = {
        login: login,
        t: (new Date()).getTime()
    }
    if (refresh) params['refresh'] = true;

    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: URL_API_ANONYMOUS + '/captcha',
        params: params,
        paramsSerializer: urlEncode
    }
    return axiosInstance(requestConfig);
}

export function getCaptchaUrl(login: string, refresh?: boolean) {
    const r = refresh ? '&refresh=true' : '';
    return URL_API_ANONYMOUS + '/captcha?' + urlEncode({ login: login }) + r + '&t=' + (new Date()).getTime();
}

export function postLogout() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_LOGOUT,
    }

    return axiosInstance(requestConfig);
}

export function postPing() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SYSTEM + '/ping',
    }
    return axiosInstance(requestConfig);
}

export function postWhoami() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SYSTEM + '/whoami',
    }
    return axiosInstance(requestConfig);
}

export function postSettingGosEnabled() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SYSTEM + '/setting_get_gos_enabled',
    }
    return axiosInstance(requestConfig);
}

export function postMyProfile() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V3_SUBJECT + '/profile/get',
    }
    return axiosInstance(requestConfig);
}

export function postMyFreeRequestsCount() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/get_my_free_requests_count',
    }
    return axiosInstance(requestConfig);
}

export function postMyBucket() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/get_my_bucket',
    }
    return axiosInstance(requestConfig);
}

export function postRequestsV2() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SUBJECT + '/request/list',
        data: {scoreVersion: "2.0"}
    }
    return axiosInstance(requestConfig);
}

export function postRequestsPkrV2() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SUBJECT + '/request/get_nkr_requests_data',
        data: {scoreVersion: "2.0"}
    }
    return axiosInstance(requestConfig);
}

export function getKiReportUrl(id, zip?: boolean | undefined) {
    return zip
        ? URL_API_SUBJECT + '/report_ki/download-get-zip-' + id + '?true'
        : URL_API_SUBJECT + '/report_ki/download-get-' + id;
}

export function postProfileConfirm(data: {
    firstName: string,
    lastName: string,
    middleName?: string,
    docType: string,
    passportNumber: string,
    passportSeries: string,
    birthday: string
}) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SUBJECT + '/profile/confirm',
        data: {
            name: data.firstName,
            surname: data.lastName,
            middleName: data.middleName,
            docType: data.docType,
            passportSeries: data.passportSeries,
            passportNumber: data.passportNumber,
            birthday: data.birthday
        }
    }
    return axiosInstance(requestConfig);
}

export function postPkrRequestByIdV2(id: number) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SUBJECT + '/request/get_nkr_request_by_id',
        data: {id: id, scoreVersion: "2.0"}
    }
    return axiosInstance(requestConfig);
}

export function postPkr12RequestByIdV2(id: number) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SUBJECT + '/request/get_nkr12_request_by_id',
        data: {id: id, scoreVersion: "2.0"}
    }
    return axiosInstance(requestConfig);
}

export function postClickBankOffer(bankOfferId: number) {
    const data = {
        bankOfferId: bankOfferId
    }
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + "/bankoffer_link_click",
        data: data
    };
    return axiosInstance(requestConfig);
}

export function postListBankOffers(pkr?: number) {
    let requestConfig: AxiosRequestConfig;
    if (!pkr) {
        requestConfig = {
            method: 'post',
            url: URL_API_V3_SUBJECT + '/list_bank_offers',
        };
    } else {
        requestConfig = {
            method: 'post',
            url: URL_API_V3_SUBJECT + '/list_bank_offers',
            data: {rating: pkr}
        };
    }
    return axiosInstance(requestConfig);
}

export function postListServiceDetails(platform: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SYSTEM + '/list_service_details',
        data: {
            platform: platform,
        },
    };
    return axiosInstance(requestConfig);
}

export function postLoginChange(newLogin: string, password: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/login_change',
        data: urlEncode({
            password: password,
            newLogin: newLogin
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
        }
    }
    return axiosInstance(requestConfig);
}


export function postMailingChange(mailing: boolean) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/mailing_change',
        data: {
            mailingMe: mailing
        }
    }
    return axiosInstance(requestConfig);
}

export function postThirdPartyAgreementChange(agreement: boolean) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/thirdPartyAgreement_change',
        data: {
            thirdPartyAgreement: agreement
        }
    };
    return axiosInstance(requestConfig);
}

export function getBankofferImageLink(id: number) {
    return URL_API_ANONYMOUS + '/get_bank_offer_logo?id=' + id;
}

export function postRequestsLastStats() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/request/last-stats',
    }
    return axiosInstance(requestConfig);
}

export function postBucketGet() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/get_my_bucket',
    }
    return axiosInstance(requestConfig);
}

export function postBucketItemPut(product: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/bucket_item_put',
        data: {
            product: product
        }
    }
    return axiosInstance(requestConfig);
}

export function postBucketItemRemove(product: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/bucket_item_remove',
        data: {
            product: product
        }
    }
    return axiosInstance(requestConfig);
}

export function postBucketConfirm(usePromoCodes: boolean, isScoreV2: boolean) {
    const data = {};
    data["usePromoCodes"] = usePromoCodes;
    if (isScoreV2)
    {
        data["scoreVersion"] = "2.0";
    }
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/bucket_confirm',
        data: data
    }
    return axiosInstance(requestConfig);
}

export function postGetPromocode(product: string, code: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/getPromoCode',
        data: {
            name: code,
            product: product
        }
    }
    return axiosInstance(requestConfig);
}

export function postRequestById(id: number) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/request/getById',
        data: {
            id: id
        }
    }
    return axiosInstance(requestConfig);
}

export function postPaymentConfirm(promoCodeId?: number, paymentMethod?: string) {
    const data = {}
    if (promoCodeId) data['promoCodeId'] = promoCodeId;
    if (paymentMethod) data['paymentMethod'] = paymentMethod;
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/payment_confirm',
        data: data
    }
    return axiosInstance(requestConfig);
}


export function postRetrieveRobokassaUrl() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/get_current_requests_robokassa_payment_url',
    }
    return axiosInstance(requestConfig);
}

export function postChangePassword(oldPassword: string, newPassword: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SYSTEM + '/change-password-authenticated',
        data: {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
    }
    return axiosInstance(requestConfig);
}

export function postOnesAcceptedEsiaClient(subjectId: number) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/once_accepted_esia_by_subject_id',
        data: {
            subjectId: subjectId,
        }
    }
    return axiosInstance(requestConfig);
}

export function postChangeLogin(currentPassword: string, newLogin: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/login-change',
        data: {
            password: currentPassword,
            newLogin: newLogin
        }
    }
    return axiosInstance(requestConfig);
}

export function postPhoneChange(phone: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/phone/change',
        data: {
            phone: phone,
        },
    };
    return axiosInstance(requestConfig);
}

export function postPhoneVerify(vcode: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/phone/verify',
        data: {
            vcode: vcode,
        },
    };
    return axiosInstance(requestConfig);
}

export function postPkrDescriptionV2(pkrValue: number) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SYSTEM + '/pkr_description_v2',
        data: {
            pkrValue: pkrValue,
        },
    };
    return axiosInstance(requestConfig);
}

export function getPkrRanges() {
    const requestConfig: AxiosRequestConfig = {
        method: 'get',
        url: URL_API_V2_SYSTEM + '/pkr_ranges_description',
        timeout: 10000
    };
    return axiosInstance(requestConfig);
}

export function postSignals(filterType: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/signals/list-signals-by-type',
        data: {
            filterType: filterType,
        },
    };
    return axiosInstance(requestConfig);
}

export function postSubscriptionInfo(filterType: string) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/subscription/info',
        data: {
            filterType: filterType,
        },
    };
    return axiosInstance(requestConfig);
}

export function postCreditHistorySearch() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_V2_SUBJECT + '/request/credit_history_search'
    }
    return axiosInstance(requestConfig);
}

export function postFeedbackOptionsList() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/feedback/question/list'
    }
    return axiosInstance(requestConfig);
}
 
export function postFeedbackTemplate() {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/feedback/template'
    }
    return axiosInstance(requestConfig);
}

export function postFeedbackNew(formData) {
    const requestConfig: AxiosRequestConfig = {
        method: 'post',
        url: URL_API_SUBJECT + '/feedback/new',
        data: formData
    }
    return axiosFormDataInstance(requestConfig);
}
