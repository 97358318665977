import {IModalComponents, IModalState} from './x_types';

export default {
    namespace: true,
    state: {
        visible: false,
        components: {} as IModalComponents
    } as IModalState,

    mutations: {
        modalSetVisible(state: IModalState, val) {
            state.visible = val;
        },
        modalSetComponents(state: IModalState, components: IModalComponents) {
            if (components)
            {
                state.components = components;
            }
        },
    },

    actions: {
        modalSetVisible(context, val) {
            context.commit('modalSetVisible', val);
        },
        modalSetComponents(context, components: IModalComponents) {
            context.commit('modalSetComponents', components)
        },
    },

    getters: {
        modalGetVisibility(state: IModalState) {
            return state.visible;
        },
        modalGetComponents(state: IModalState) {
            return state.components;
        },
    }
}
