export const enum PRODUCTS {
    FRAUD_PROTECTION = 'FRAUD_PROTECTION',
    KI = 'KI',
    KI_GET = 'KI_GET',
    KI_CHANGE = 'KI_CHANGE',
    PKR = 'NKR',
    PKR12 = 'NKR12',
}

export const PRODUCT = Object.freeze({
    FRAUD_PROTECTION: "FRAUD_PROTECTION",
    KI: "KI",
    KI_GET: "KI_GET",
    KI_CHANGE: "KI_CHANGE",
    PKR: "PKR",
    PKR12: "PKR12",
});