import {IRequestsLastStatsData, IRequestsLastStatsState} from './x_types';
import {LOG__VUEX_COMMITS} from "@/misc/Config";

export default {
    namespace: true,
    state: {
        dt: undefined,
        loadStatus: undefined,
        data: undefined,
    } as IRequestsLastStatsState,

    mutations: {
        requestsLastStatsRefreshDt(state: IRequestsLastStatsState) {
            state.dt = (new Date()).getTime();
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> requestsLastStatsRefreshDt')
        },
        requestsLastStatsSetLoadStatus(state: IRequestsLastStatsState, status?: string) {
            state.loadStatus = status;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> requestsLastStatsSetLoadStatus:', state.loadStatus)
        },
        requestsLastStatsSetData(state: IRequestsLastStatsState, data: IRequestsLastStatsData) {
            state.data = data;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> requestsLastStatsSetData:', state.data)
        },
    },

    getters: {
        requestsLastStatsGetAgeMs(state: IRequestsLastStatsState) {
            const now = (new Date()).getTime();
            const past = (new Date(Date.UTC(-271821, 3, 20))).getTime()
            const was = state.dt ? state.dt : past;
            return now - was;
        },
        requestsLastStatsGetLoadStatus(state: IRequestsLastStatsState) {
            return state.loadStatus;
        },
        requestsLastStatsGetData(state: IRequestsLastStatsState): IRequestsLastStatsData | undefined {
            return state.data ? state.data : undefined;
        },
    }
}
