export const enum ROUTING {
    ROOT = 'root',

    LOGIN = 'login',
    REGISTER_STEP_1 = 'register-step-1',
    REGISTER_STEP_2 = 'register-step-2',
    REGISTER_STEP_2_ERROR = 'register-step-2/error',
    REGISTER_STEP_2_SUCCESS = 'register-step-2/success',
    REGISTER_STEP_3 = 'register-step-3',
    ESIA_REGISTER_STEP_2 = 'esia-register-step-2',
    ESIA_REGISTER_STEP_3 = 'esia-register-step-3',
    ESIA_REGISTER_USER_NOT_FOUND = 'esia-register-user-not-found',
    FORGOT = 'forgot',
    RESTORE = 'restore',

    PRODUCTS = 'products',
    PRODUCT_KI_INFO = 'product-ki-info',
    PRODUCT_PKR_INFO = 'product-pkr-info',
    PRODUCT_PKR12_INFO = 'product-pkr12-info',
    PRODUCT_CREDIT_HISTORY_SUBSCRIPTION_INFO = 'product-credit-history-subscription-info',
    PRODUCT_FRAUD_PROTECTION_INFO = 'product-fraud-protection-info',
    PRODUCT_KI_CHANGE_INFO = 'product-ki-change-info',

    PROFILE = 'profile',
    PROFILE_EMAIL = 'profile-email',
    PROFILE_SUBSCRIPTIONS = 'profile-subscriptions',
    PROFILE_PASSWORD = 'profile-password',

    HISTORY = 'history',
    HISTORY_PKR = 'history-pkr',
    HISTORY_PKR12 = 'history-pkr12',
    HISTORY_FRAUD_PROTECTION = 'history-fraud-protection',
    HISTORY_KI_CHANGE = 'history-ki-change',
    HISTORY_KI_CHANGE_ADDITIONAL = 'history-ki-change-additional',

    OVERVIEW = 'overview',
    BANKOFFERS = 'bankoffers',
    CHECKOUT = 'checkout',

    ERROR_SERVICE_NA = 'error-service-na',
    ERROR_API = 'error-api',
    ERROR_NOT_A_SUBJECT = 'error-not-a-subject',
    ERROR_NOT_AUTHORIZED = 'error-not-authorized',

    FEEDBACK = 'feedback',
}
