
import '@/assets/css2/main.scss'

import {computed, ComputedRef, defineComponent} from 'vue'
import ScAuthHeading from '@/components/auth/ScAuthHeading.vue'
import ScFooter from '@/components/ScFooter.vue'
import ApiSystemInfo from '@/services/api/system/ApiSystemInfo'
import {IFail} from "@/services/errors/IFail";
import {defaultFailHandle} from "@/misc/ErrorHandler";
import router from "@/router";
import {ROUTING} from "@/misc/enums/EnumRouting";

export default defineComponent({
  components: {
    ScFooter,
    ScAuthHeading,
  },
  setup() {
    ApiSystemInfo.run()
        .catch((fail: IFail) => {
          if (!defaultFailHandle(fail)) {
            console.log('-> redirect from p5');
            router.push(ROUTING.LOGIN); //+
          }
        });

    const loadingDone: ComputedRef<boolean> = computed(() => ApiSystemInfo.isLoadDone());

    return {
      loadingDone
    }
  }
})
