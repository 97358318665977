import {IAuthState} from './x_types'
import {LOG__VUEX_COMMITS} from "@/misc/Config";

export default {
    namespace: true,
    state: {
        menuActive: undefined,
        rememberMe: false,
        lastLogin: undefined
    } as IAuthState,

    mutations: {
        authSetMenuActive(state: IAuthState, menuActive: string) {
            state.menuActive = menuActive;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> authSetMenuActive:', state.menuActive)
        },
        authSetRememberMe(state: IAuthState, rememberMe: boolean) {
            state.rememberMe = rememberMe;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> authSetRememberMe:', state.rememberMe)
        },
        authSetLastLogin(state: IAuthState, lastLogin: string) {
            state.lastLogin = lastLogin;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> authSetLastLogin:', state.lastLogin)
        },
    },

    actions: {
        authSetMenuActive(context, menuActive: string) {
            context.commit('authSetMenuActive', menuActive)
        },
        authSetRememberMe(context, rememberMe: boolean) {
            context.commit('authSetRememberMe', rememberMe)
        },
        authSetLastLogin(context, lastLogin: string) {
            context.commit('authSetLastLogin', lastLogin)
        },
    },

    getters: {
        authGetMenuActive(state: IAuthState) {
            return state.menuActive
        },
        authGetRememberMe(state: IAuthState) {
            return state.rememberMe
        },
        authGetLastLogin(state: IAuthState) {
            return state.lastLogin
        },
    }
}
