
import store from '@/store';
import { Modal } from 'bootstrap';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const profileData = computed(() => {
      return store.getters.profileGetData ? store.getters.profileGetData : {}
    })

    return {
      profileData
    }
  },

  methods: {
    showScDebugPanel() {
      const elements = document.getElementsByClassName('debug-panel');
      (new Modal(elements[0], {})).show();
    }
  }
})
