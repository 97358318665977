import { MAX_RELOAD_RETRIES } from "@/misc/Config";
import { BACKEND_ERROR_CODE } from "@/misc/enums/EnumBackendErrorCodes";
import { LOAD_STATUS } from "@/misc/enums/EnumLoadStatus";
import { postMyProfile } from '@/services/Backend';
import { IBackendFail } from "@/services/errors/IBackendFail";
import { IFail } from "@/services/errors/IFail";
import store from '@/store';
import { AxiosResponse } from "axios";

const NAME = 'ApiProfileGet';

export default {
    name: NAME,
    run() {
        console.log('ooo >> ' + NAME + ' start');

        //store.commit('profileSetData', undefined);
        store.commit('profileSetLoadStatus', LOAD_STATUS.LOADING);

        const onSuccess = function (axiosResponse: AxiosResponse) {
            console.log('ooo << ' + NAME + ' [status =', axiosResponse.status + '] [data =', axiosResponse.data, ']');
            if (axiosResponse.data === undefined) return execute();
            const data = axiosResponse.data;
            store.commit('profileSetData', data);
            store.commit('profileSetLoadStatus', LOAD_STATUS.DONE);
            return Promise.resolve(data);
        }

        let retries = 0;
        const execute = function () {
            if (retries <= MAX_RELOAD_RETRIES) {
                retries++;
                return postMyProfile().then(onSuccess).catch(onError);
            } else {
                return Promise.reject({
                    errorCode: BACKEND_ERROR_CODE.UNAUTHORIZED
                } as IFail)
            }
        }

        const onError = function (fail: IBackendFail) {
            console.log('ooo << ' + NAME + ' [fail =', fail, ']');
            if (fail.errorCode === BACKEND_ERROR_CODE.NEED_RELOAD) return execute();
            return Promise.reject(fail);
        }

        return execute();
    }
}