import router from "@/router";

import {IFail} from "@/services/errors/IFail";
import {ROUTING} from "@/misc/enums/EnumRouting";
import {BACKEND_ERROR_CODE} from "@/misc/enums/EnumBackendErrorCodes";

const routeMatcher = new Map([
    [BACKEND_ERROR_CODE.NOT_A_SUBJECT, ROUTING.ERROR_NOT_A_SUBJECT],
    [BACKEND_ERROR_CODE.NETWORK_ERROR, ROUTING.ERROR_SERVICE_NA],
    [BACKEND_ERROR_CODE.UNAUTHORIZED, ROUTING.ERROR_NOT_AUTHORIZED],
    [BACKEND_ERROR_CODE.GO_TO_LOGIN, ROUTING.LOGIN],
]);

export function defaultFailHandle(fail: IFail): boolean {
    console.log('ERROR:', fail);
    if (fail.errorCode) {
        const errorRoute = routeMatcher.get(fail.errorCode as BACKEND_ERROR_CODE);
        if (errorRoute) {
            router.push(errorRoute).then(err => err && console.log('VUE_ROUTER err:', err));
            return true;
        }
    }
    return false;
}
