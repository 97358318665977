import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "app"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetricsComponentW = _resolveComponent("MetricsComponentW")!
  const _component_ScModal = _resolveComponent("ScModal")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MetricsComponentW),
    (_ctx.$route.meta)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ScModal),
          _createVNode(_component_Toast),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2))
  ], 64))
}