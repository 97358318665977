
import {computed, defineComponent, onMounted, watch} from 'vue';
import {useRoute} from 'vue-router'
import store from '@/store';

import Dialog from 'primevue/dialog';

export default defineComponent({
  name: 'ScModal',
  components: {Dialog},
  setup() {
    const visible = computed({
      get: (): boolean => store.getters.modalGetVisibility,
      set: (val) => store.dispatch('modalSetVisible', val),
    });

    const body = computed(() => store.getters.modalGetComponents?.body);
    const header = computed(() => store.getters.modalGetComponents?.header);
    const footer = computed(() => store.getters.modalGetComponents?.footer);

    const route = useRoute();
    onMounted(() => {
      watch(() => route.name, () => {
        store.dispatch('modalSetVisible', false)
      }, {immediate: false});
    });

    return {
      visible,
      body, header, footer,
      route
    };
  }

});

