import 'core-js/stable';
import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import '@/assets/css2/a-root.scss'
import {MetricsService} from '@/services/MetricsService'
import 'bootstrap/scss/bootstrap.scss'

import '@/assets/fonts/exo2/exo2.css'
import '@/assets/fonts/Formular/Formular.css'
import '@/assets/fonts/FormularMono/FormularMono.css'
import '@/assets/css/validate-message.scss'
import * as Cookies from '@/../node_modules/js-cookie'
import {VUE_CABINET_COOKIE_NAME} from "@/misc/Config";

Cookies.set(VUE_CABINET_COOKIE_NAME, true, {expires: 365, secure: false})

const app = createApp(App);
app.config.globalProperties.$metricsService = MetricsService.getInstance(window);
app.use(store)
    .use(router)
    .use(PrimeVue)
    .use(ToastService)
    .mount('#app');