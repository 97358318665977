import {createRouter, createWebHistory} from 'vue-router';
import {ROUTING} from '@/misc/enums/EnumRouting';

export default createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        setTimeout(() => window.scrollTo(0, 0), 10);
    },
    routes: [
        {
            path: '/',
            name: ROUTING.ROOT,
            component: () => import('@/views/RootView.vue'),
            meta: {layout: 'auth'}
        }, {
            path: '/' + ROUTING.LOGIN,
            name: ROUTING.LOGIN,
            component: () => import('@/views/auth/AuthLoginView.vue'),
            meta: {layout: 'auth', title: 'Личный кабинет в НБКИ – кредитные истории и ПКР для лучшего контроля своих финансов'}
        }, {
            path: '/' + ROUTING.REGISTER_STEP_1,
            name: ROUTING.REGISTER_STEP_1,
            component: () => import('@/views/auth/AuthRegisterStep1View.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.REGISTER_STEP_2,
            name: ROUTING.REGISTER_STEP_2,
            component: () => import('@/views/auth/AuthRegisterStep2View.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.REGISTER_STEP_2_ERROR,
            name: ROUTING.REGISTER_STEP_2_ERROR,
            component: () => import('@/views/auth/AuthRegisterStep2ErrorView.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.REGISTER_STEP_2_SUCCESS,
            name: ROUTING.REGISTER_STEP_2_SUCCESS,
            component: () => import('@/views/auth/AuthRegisterStep2SuccessView.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.REGISTER_STEP_3,
            name: ROUTING.REGISTER_STEP_3,
            component: () => import('@/views/auth/AuthRegisterStep3View.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.ESIA_REGISTER_STEP_2,
            name: ROUTING.ESIA_REGISTER_STEP_2,
            component: () => import('@/views/auth/esia/AuthRegisterStep2EsiaView.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.ESIA_REGISTER_STEP_3,
            name: ROUTING.ESIA_REGISTER_STEP_3,
            component: () => import('@/views/auth/esia/AuthRegisterStep3EsiaView.vue'),
            meta: {layout: 'auth', title: 'Регистрация'}
        }, {
            path: '/' + ROUTING.ESIA_REGISTER_USER_NOT_FOUND,
            name: ROUTING.ESIA_REGISTER_USER_NOT_FOUND,
            component: () => import('@/views/auth/esia/AuthRegisterEsiaUserNotFound.vue'),
            meta: {layout: 'auth', title: 'Пользователь не найден'}
        }, {
            path: '/' + ROUTING.FORGOT,
            name: ROUTING.FORGOT,
            component: () => import('@/views/auth/AuthForgotView.vue'),
            meta: {layout: 'auth', title: 'Восстановление'}
        }, {
            path: '/' + ROUTING.RESTORE,
            name: ROUTING.RESTORE,
            component: () => import('@/views/auth/AuthRestorePassword.vue'),
            meta: {layout: 'auth', title: 'Восстановление'}
        }, {
            path: '/' + ROUTING.ERROR_SERVICE_NA,
            name: ROUTING.ERROR_SERVICE_NA,
            component: () => import('@/views/error/ErrorServiceNAView.vue'),
            meta: {layout: 'error', title: 'Ошибка: сервер недоступен'}
        }, {
            path: '/' + ROUTING.ERROR_API,
            name: ROUTING.ERROR_API,
            component: () => import('@/views/error/ErrorApiView.vue'),
            meta: {layout: 'error', title: 'Ошибка API'}
        }, {
            path: '/' + ROUTING.ERROR_NOT_A_SUBJECT,
            name: ROUTING.ERROR_NOT_A_SUBJECT,
            component: () => import('@/views/error/ErrorNotASubjectView.vue'),
            meta: {layout: 'error'}
        }, {
            path: '/' + ROUTING.ERROR_NOT_AUTHORIZED,
            name: ROUTING.ERROR_NOT_AUTHORIZED,
            component: () => import('@/views/error/ErrorNotAuthorizedView.vue'),
            meta: {layout: 'error'}
        }, {
            path: '/' + ROUTING.PRODUCTS,
            name: ROUTING.PRODUCTS,
            component: () => import('@/views/main/products/MainProductsView.vue'),
            meta: {layout: 'main', title: 'Все услуги', breadcrumbText: 'Услуги', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.PRODUCT_KI_INFO,
            name: ROUTING.PRODUCT_KI_INFO,
            component: () => import('@/views/main/products/MainProductKiInfoView.vue'),
            meta: {layout: 'main', title: 'Кредитный отчет', breadcrumbText: 'Кредитная история', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.PRODUCT_PKR_INFO,
            name: ROUTING.PRODUCT_PKR_INFO,
            component: () => import('@/views/main/products/MainProductPkrInfoView.vue'),
            meta: {layout: 'main', title: 'Персональный кредитный рейтинг', breadcrumbText: 'ПКР', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.PRODUCT_PKR12_INFO,
            name: ROUTING.PRODUCT_PKR12_INFO,
            component: () => import('@/views/main/products/MainProductPkr12InfoView.vue'),
            meta: {layout: 'main', title: 'Персональный кредитный рейтинг за 12 месяцев', breadcrumbText: 'ПКР 12', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.PRODUCT_CREDIT_HISTORY_SUBSCRIPTION_INFO,
            name: ROUTING.PRODUCT_CREDIT_HISTORY_SUBSCRIPTION_INFO,
            component: () => import('@/views/main/products/MainProductCreditHistorySubscriptionInfoView.vue'),
            meta: {layout: 'main', title: 'Подписка на кредитную историю', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.PRODUCT_FRAUD_PROTECTION_INFO,
            name: ROUTING.PRODUCT_FRAUD_PROTECTION_INFO,
            component: () => import('@/views/main/products/MainProductFraudProtectionInfoView.vue'),
            meta: {layout: 'main', title: 'Защита от мошенничества', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.PRODUCT_KI_CHANGE_INFO,
            name: ROUTING.PRODUCT_KI_CHANGE_INFO,
            component: () => import('@/views/main/products/MainProductKiChangeInfoView.vue'),
            meta: {layout: 'main', title: 'Изменения в кредитной истории', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.PROFILE,
            name: ROUTING.PROFILE,
            component: () => import('@/views/main/profile/MainProfileView.vue'),
            meta: {layout: 'main', title: 'Мой профиль', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.HISTORY,
            name: ROUTING.HISTORY,
            component: () => import('@/views/main/history/MainHistoryView.vue'),
            meta: {layout: 'main', title: 'История заказов',  breadcrumbText: 'История', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.HISTORY_PKR12,
            name: ROUTING.HISTORY_PKR12,
            component: () => import('@/views/main/history/MainHistoryPkr12View.vue'),
            meta: {layout: 'main', title: 'Персональный кредитный рейтинг за 12 месяцев - отчет', breadcrumbText: 'ПКР 12', parent: ROUTING.PRODUCTS}
        }, {
            path: '/' + ROUTING.HISTORY_FRAUD_PROTECTION,
            name: ROUTING.HISTORY_FRAUD_PROTECTION,
            component: () => import('@/views/main/history/MainHistoryFraudProtection.vue'),
            meta: {
                layout: 'main',
                title: 'История сигналов защиты от мошенничества',
                breadcrumbText: 'Защита от мошенничества',
                parent: ROUTING.PRODUCTS
            }
        }, {
            path: '/' + ROUTING.HISTORY_KI_CHANGE,
            name: ROUTING.HISTORY_KI_CHANGE,
            component: () => import('@/views/main/history/MainHistoryKiChange.vue'),
            meta: {layout: 'main', title: 'Изменения в кредитной истории', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.HISTORY_KI_CHANGE_ADDITIONAL,
            name: ROUTING.HISTORY_KI_CHANGE_ADDITIONAL,
            component: () => import('@/views/main/history/MainHistoryKiChangeAdditional.vue'),
            meta: {layout: 'main', title: 'Изменения в кредитной истории', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.OVERVIEW,
            name: ROUTING.OVERVIEW,
            component: () => import('@/views/main/overview/MainOverviewView.vue'),
            meta: {layout: 'main', breadcrumbText: 'Обзор', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.BANKOFFERS,
            name: ROUTING.BANKOFFERS,
            component: () => import('@/views/main/overview/MainBankoffersView.vue'),
            meta: {
                layout: 'main',
                title: 'Предложения банков',
                breadcrumbText: 'Кредитные предложения',
                parent: ROUTING.ROOT
            }
        }, {
            path: '/' + ROUTING.CHECKOUT,
            name: ROUTING.CHECKOUT,
            component: () => import('@/views/main/checkout/MainCheckoutView.vue'),
            meta: {layout: 'main', title: 'Оплата заказа', breadcrumbText: 'Оплата', parent: ROUTING.ROOT}
        }, {
            path: '/' + ROUTING.FEEDBACK,
            name: ROUTING.FEEDBACK,
            component: () => import('@/views/main/FeedbackPage.vue'),
            meta: {layout: 'main', title: 'Обратная связи', breadcrumbText: 'Обратная связь', parent: ROUTING.ROOT}
        }
    ]
})
