import {createStore} from 'vuex'
//
import createPersistedState from '@/../node_modules/vuex-persistedstate'
import * as Cookies from '@/../node_modules/js-cookie'
//
import SystemInfoModule from '@/store/modules/system_info/SystemInfoModule';
import ProfileModule from '@/store/modules/profile/ProfileModule';
import AuthModule from '@/store/modules/auth/AuthModule';
import EsiaModule from '@/store/modules/esia/EsiaModule';
import MainModule from '@/store/modules/main/MainModule';
import WhoamiModule from '@/store/modules/whoami/WhoamiModule';
import RequestsLastStatsModule from '@/store/modules/requests_last_stats/RequestsLastStatsModule';
import ModalModule from '@/store/modules/modal/ModalModule';

import {ISystemInfoState} from '@/store/modules/system_info/x_types';
import {IProfileState} from '@/store/modules/profile/x_types';
import {IAuthState} from '@/store/modules/auth/x_types';
import {IEsiaState} from '@/store/modules/esia/x_types';
import {IMainState} from '@/store/modules/main/x_types';
import {IWhoamiState} from '@/store/modules/whoami/x_types';
import {IModalState} from "@/store/modules/modal/x_types";

import {VUEX_PERSIST_COOKIES} from "@/misc/Config";

interface IStore {
    auth: IAuthState,
    esia: IEsiaState,
    systemInfo: ISystemInfoState,
    profile: IProfileState,
    whoami: IWhoamiState,
    main: IMainState,
    modal: IModalState,
}

Cookies.remove('vuex');
const paths = [
    'auth',
    'esia',
    'systemInfo',
    'profile',
    'whoami',
    'main',
    'requestsLastStats',
    'modal'
];

let persistansePlugin;
if (VUEX_PERSIST_COOKIES){
    persistansePlugin = createPersistedState({
        paths: paths,
        getState: (key) => {
            // console.log('===================', key);
            const vuex = {};
            vuex['auth'] = Cookies.getJSON('sc-auth') ?? {};
            vuex['esia'] = Cookies.getJSON('sc-esia') ?? {};
            vuex['systemInfo'] = Cookies.getJSON('sc-systemInfo') ?? {};
            vuex['profile'] = Cookies.getJSON('sc-profile') ?? {};
            vuex['whoami'] = Cookies.getJSON('sc-whoami') ?? {};
            vuex['main'] = Cookies.getJSON('sc-main') ?? {};
            vuex['requestsLastStats'] = Cookies.getJSON('sc-requestsLastStats') ?? {};
            // console.log('===================', vuex);
            return vuex;
        },
        setState: (key, value) => {
            // console.log('+++++++++++++++++++', key, value);
            Cookies.set('sc-auth', value.auth, {expires: 365, secure: false});
            Cookies.set('sc-esia', value.esia, {expires: 365, secure: false});
            Cookies.set('sc-systemInfo', value.systemInfo, {expires: 365, secure: false});
            Cookies.set('sc-profile', value.profile, {expires: 365, secure: false});
            Cookies.set('sc-whoami', value.whoami, {expires: 365, secure: false});
            Cookies.set('sc-main', value.main, {expires: 365, secure: false});
            Cookies.set('sc-requestsLastStats', value.requestsLastStats, {expires: 365, secure: false});
        },
    });
} else {
    Cookies.remove('sc-auth');
    Cookies.remove('sc-esia');
    Cookies.remove('sc-systemInfo');
    Cookies.remove('sc-profile');
    Cookies.remove('sc-whoami');
    Cookies.remove('sc-main');
    Cookies.remove('sc-requestsLastStats');

    persistansePlugin = createPersistedState({
        paths: paths,
    });
}

export default createStore({
    state: {} as IStore,
    modules: {
        auth: AuthModule,
        esia: EsiaModule,
        systemInfo: SystemInfoModule,
        profile: ProfileModule,
        whoami: WhoamiModule,
        main: MainModule,
        requestsLastStats: RequestsLastStatsModule,
        modal: ModalModule,
    },
    plugins: [persistansePlugin]
})
