import ParseDate from 'date-fns/parse'
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip
} from "chart.js";
import {REQUEST_STATUS} from "@/misc/enums/EnumRequestStatuses";
import ApiBankOffersClick from "@/services/api/bankoffers/ApiBankOffersClick";

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Filler,
    Legend,
    Title,
    Tooltip
);

export function urlEncode(data: object) {
    const urlEncodedDataPairs: string[] = [];
    for (const key in data) {
// noinspection JSUnfilteredForInLoop
        const pair = encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        urlEncodedDataPairs.push(pair);
    }
    return urlEncodedDataPairs.join('&').replace(/%20/g, '+');
}

export function dtFormatAnsiToDate(dt: string): Date {
    if (!dt) return new Date();
    return ParseDate(dt, 'yyyy-MM-dd', new Date())
}

export function dtFormatToRus(d?: Date): string {
    const dt = d ? d : new Date();
    return ("0" + dt.getDate()).slice(-2) + "." + ("0" + (dt.getMonth() + 1)).slice(-2) + "." + dt.getFullYear();
}

export function dtFormatAnsiToRus(dt: string): string {
    if (!dt) return dt;
    return dt.substr(8, 2) + '.' + dt.substr(5, 2) + '.' + dt.substr(0, 4)
}

export function dtFormatAnsiToRusTime(dt: string): string {
    if (!dt) return dt;
    return dt.slice(11, 16);
}

export function dtFormatRusToAnsi(dt?: string | null): string | undefined {
    if (!dt) return undefined;
    return dt.substr(6, 4) + '-' + dt.substr(3, 2) + '-' + dt.substr(0, 2)
}

const aNumericCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export function countNumericCharacters(str: string) {
    if (!str)
        return 0;
    let c = 0;
    for (let i = 0; i < str.length; i++) {
        if (aNumericCharacters.indexOf(str[i]) !== -1)
            c++;
    }
    return c;
}

export function epochToMonthStr(epoch: number) {
    const dt = new Date(epoch);
    switch (dt.getMonth()) {
        case 0:
            return 'Январь';
        case 1:
            return 'Февраль';
        case 2:
            return 'Март';
        case 3:
            return 'Апрель';
        case 4:
            return 'Май';
        case 5:
            return 'Июнь';
        case 6:
            return 'Июль';
        case 7:
            return 'Август';
        case 8:
            return 'Сентябрь';
        case 9:
            return 'Октябрь';
        case 10:
            return 'Ноябрь';
        case 11:
            return 'Декабрь';
        default:
            return '?';
    }
}

export function isStatusFinal(statusNameEng: string): boolean {
    if (statusNameEng === REQUEST_STATUS.DONE) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.CANCELLED) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.SC_ACCEPTED) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.PAY_OR_NOT) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.SC_REJECTED) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.GOS_ACCEPTED) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.PAY_WAIT) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.GOS_REJECTED) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.GOS_NOT_CONFIRMED) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.CONFIRMING) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.DBO_MUST_RETRY_SEND) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.ERROR_NKR_NOT_AVAIL) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.ERROR_NKR_NOT_INFORMATIVE) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.ERROR_NKR_NO_CREDS_BEFORE) {
        return true;
    } else if (statusNameEng === REQUEST_STATUS.PROMO_CODE) {
        return false;
    } else if (statusNameEng === REQUEST_STATUS.ERROR) {
        return true;
    } else {
        return true;
    }
}

export function formatInteger(xx: number, sep?: string, grp?: number) : string {
    if (!xx) {
        return '';
    }
    const x = '' + xx;
    const sx = (''+x).split('.');
    let s = '';
    let i: number;
    let j: number;
    sep || (sep = ' '); // default seperator
    grp || grp === 0 || (grp = 3); // default grouping
    i = sx[0].length;
    while (i > grp) {
        j = i - grp;
        s = sep + sx[0].slice(j, i) + s;
        i = j;
    }
    s = sx[0].slice(0, i) + s;
    sx[0] = s;
    return sx.join('.');
}

export function shuffleArray(array: Array<any>) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export const getTermUnit = (term: number) => {
    if (term % 10 === 1) {
        return '-го года'
    } else if (term % 10 === 2) {
        return '-ух лет'
    } else if (term % 10 === 3 || term % 10 === 4) {
        return '-ёх лет'
    } else {
        return '-и лет'
    }
}

export const clickBankOffer = (bankOfferId: number, url: string) => {
    ApiBankOffersClick.run({bankOfferId: bankOfferId}).then(() => {
        setTimeout(() => window.open(url));
    });
};
