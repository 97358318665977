import {IEsiaState} from './x_types';
import {LOG__VUEX_COMMITS} from "@/misc/Config";

export default {
    namespace: true,
    state: {
        dt: undefined,
        loadStatus: undefined,
        enabled: undefined
    } as IEsiaState,
    mutations: {
        esiaSetLoadStatus(state: IEsiaState, loadStatus?: string) {
            state.loadStatus = loadStatus;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> esiaSetLoadStatus:', state.loadStatus)
        },
        esiaSetEnabled(state: IEsiaState, enabled?: boolean) {
            state.dt = new Date();
            state.enabled = enabled;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> esiaSetEnabled:', state.enabled)
        },
    },

    actions: {
        esiaSetLoadStatus(context, loadStatus?: string) {
            context.commit('esiaSetLoadStatus', loadStatus);
        },
        esiaSetEnabled(context, enabled?: boolean) {
            context.commit('esiaSetEnabled', enabled);
        },
    },

    getters: {
        esiaGetLoadStatus(state: IEsiaState) {
            return state.loadStatus;
        },
        esiaGetEnabled(state: IEsiaState) {
            return state.enabled;
        },
    }
}
