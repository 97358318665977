import {IMainState} from './x_types';
import {LOG__VUEX_COMMITS} from "@/misc/Config";
import {HISTORY_PKR12_TAB_TYPE} from "@/misc/enums/EnumHistoryPkr12TabType";

export default {
    namespace: true,
    state: {} as IMainState,

    mutations: {
        mainSetLeftPanelMenuActive(state: IMainState, tabName?: string) {
            state.leftPanelMenuActive = tabName;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> mainSetLeftPanelMenuActive:', state.leftPanelMenuActive);
        },
        mainSetCurrentHistoryPkrReportId(state: IMainState, id?: number) {
            state.currentHistoryPkrReportId = id;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> mainSetCurrentHistoryPkrReportId:', state.currentHistoryPkrReportId);
        },
        mainSetCurrentHistoryPkr12ReportId(state: IMainState, id?: number) {
            state.currentHistoryPkr12ReportId = id;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> mainSetCurrentHistoryPkr12ReportId:', state.currentHistoryPkr12ReportId);
        },
        mainSetCreatedUsername(state: IMainState, username?: string) {
            state.createdUsername = username;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> mainSetCreatedUsername:', state.createdUsername);
        },
        mainSetHistoryPkr12TabType(state: IMainState, tabType: HISTORY_PKR12_TAB_TYPE) {
            state.historyPkr12TabType = tabType;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> mainSetHistoryPkr12TabType:', state.historyPkr12TabType);
        },
        mainSetChargeProduct(state: IMainState, product: string) {
            state.chargeProduct = product;
            if (LOG__VUEX_COMMITS) console.log('vuex commit -> mainSetChargeProduct:', state.chargeProduct);
        },
    },

    actions: {
        mainSetLeftPanelMenuActive(context, tabName?: string) {
            context.commit('mainSetLeftPanelMenuActive', tabName)
        },
        mainSetCurrentHistoryPkrReportId(context, id?: number) {
            context.commit('mainSetCurrentHistoryPkrReportId', id)
        },
        mainSetCurrentHistoryPkr12ReportId(context, id?: number) {
            context.commit('mainSetCurrentHistoryPkr12ReportId', id)
        },
        mainSetCreatedUsername(context, username?: string) {
            context.commit('mainSetCreatedUsername', username);
        },
        mainSetHistoryPkr12TabType(context, tabType: HISTORY_PKR12_TAB_TYPE) {
            context.commit('mainSetHistoryPkr12TabType', tabType)
        },
        mainSetChargeProduct(context, product: string) {
            context.commit('mainSetChargeProduct', product)
        },
    },

    getters: {
        mainGetLeftPanelMenuActive(state: IMainState) {
            return state.leftPanelMenuActive;
        },
        mainGetCurrentHistoryPkrReportId(state: IMainState) {
            return state.currentHistoryPkrReportId;
        },
        mainGetCurrentHistoryPkr12ReportId(state: IMainState) {
            return state.currentHistoryPkr12ReportId;
        },
        mainGetCreatedUsername(state: IMainState) {
            return state.createdUsername;
        },
        mainGetHistoryPkr12TabType(state: IMainState) {
            return state.historyPkr12TabType;
        },
        mainGetChargeProduct(state: IMainState) {
            return state.chargeProduct;
        },
    }
}
