
import '@/assets/css2/header-main.scss'
import '@/assets/css2/nav.scss'
import {computed, ComputedRef, defineComponent, ref} from 'vue'
import store from '@/store'
import ScDebugPanel from '@/components/ScDebugPanel.vue'
import {ROUTING} from '@/misc/enums/EnumRouting'
import {REQUEST_STATUS} from "@/misc/enums/EnumRequestStatuses";
import ApiLogout from '@/services/api/auth/ApiLogout'

import ScMainProductCardTooltipHelp from "@/components/main/products/card/ScMainProductCardTooltipHelp.vue";

export default defineComponent({
  components: {ScDebugPanel, ScMainProductCardTooltipHelp},
  setup() {
    const requestsLastStats = computed(() => store.getters.requestsLastStatsGetData);
    const isPkrAvailable: ComputedRef<boolean> = computed(() => Boolean(requestsLastStats.value && requestsLastStats.value.pkr));

    const profileData = computed(() => store.getters.profileGetData);
    const profileConfirmed = computed(() => profileData.value && profileData.value.confirmed === REQUEST_STATUS.GOS_ACCEPTED)
    const whoamiData = computed(() => store.getters.whoamiGetData);
    const userTitle = ref('');
    const showMenu = ref(false);
    const menuItems = ref([
      {title: 'Обзор', link: ROUTING.OVERVIEW, disabled: !isPkrAvailable.value},
      {title: 'Мои услуги', link: ROUTING.PRODUCTS},
      {title: 'Мой профиль', link: ROUTING.PROFILE},
      {title: 'История заказов', link: ROUTING.HISTORY},
    ]);

    const fio = computed(() => {
      if (profileData.value
          && profileData.value.me
          && profileData.value.me.identityLast
          && profileData.value.me.identityLast.fio) {
        return profileData.value.me.identityLast.fio;
      } else if (whoamiData.value && whoamiData.value.username) {
        return whoamiData.value.username;
      } else {
        return '';
      }
    })

    if (fio.value) {
      userTitle.value = fio.value
    }

    return {
      profileData,
      profileConfirmed,
      whoamiData,
      userTitle,
      fio,
      showMenu,
      menuItems,
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
      const headerMainRef: HTMLElement = this.$refs.headerMainRef as HTMLElement;
      if (headerMainRef) {
        if (window.scrollY >= 100) {
          headerMainRef.classList.add('scrolled')
        } else {
          headerMainRef.classList.remove('scrolled')
        }
      }
    });
  },

  methods: {
    onProfileClick() {
      this.$router.push(ROUTING.PROFILE);
    },
    onLogoutClick() {
      ApiLogout.run();
    },
    onMenuToggle() {
      console.log('--- menu toggle ---')
      this.showMenu = !this.showMenu;
    }
  },

  watch: {
    profileData() {
      this.userTitle = this.fio;
    },
    whoamiData() {
      this.userTitle = this.fio;
    },

  }
})
